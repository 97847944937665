export default (data = {}) => {
    return {
        account: null,
        project: null,
        task: null,
        code: null,
        name: null,
        pr_length: null,
        pr_width: null,
        pr_height: null,
        pr_volume: null,
        pr_area: null,
        note: null,
        issue_date: null,
        agent: null,
        picture: null,
        ...data
    }
}
