<template>
  <v-container>
    <v-toolbar flat>
      <v-toolbar-title>Events</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn fab dark small color="success" @click="newForm">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-sheet elevation="0" class="pa-2 mt-3">
      <v-row class="pa-0">
        <v-col cols="12" md="6">
          <v-card elevation="10" height="100%" class="card-outter">
            <v-card-title v-text="this.project.name"></v-card-title>
            <v-card-subtitle>
              <small> {{ this.project.issue_date }} / {{ this.project.due_date }} </small>
            </v-card-subtitle>
            <v-card-text v-text="this.project.project"></v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card elevation="10" height="100%" class="card-outter">
            <v-card-title>
              {{ this.task.name }}
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-subtitle>
              <small>{{ this.task.issue_date }}</small>
            </v-card-subtitle>
            <v-card-text>
              Largo: {{ this.task.pr_length }}, Ancho: {{ this.task.pr_width }}, Espesor:
              {{ this.task.pr_height }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="pa-0">
        <v-col v-for="(child, index) in items" :key="index" cols="12" md="12" sm="12">
          <v-card elevation="10" max-width="350px" class="d-flex flex-column pa-2">
            <v-img
              max-height="350px"
              width="100%"
              contain
              :src="getImagePhoto(child.account, child.picture)"
              v-if="child.picture"
            ></v-img>
            <v-card-title v-text="child.name"></v-card-title>
            <v-card-text v-text="child.project"></v-card-text>
            <v-card-actions class="pa-2">
              {{ child.issue_date }} / {{ child.due_date }}
              <v-spacer></v-spacer>
              <v-btn dark @click="handleClick(child)">Edit</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
    <EventForm
      :table="table"
      :item="item"
      :dialog="dialog"
      :newItem="newItem"
      @close="dialog = false"
      @refresh="refresh()"
      @newOrder="newOrder(item)"
    />

    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando ...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver, getIssueDate, getToday } from "../../services/webserver.js";
import createItem from "../../utils/create_event.js";
import EventForm from "../../components/EventForm.vue";
import createProject from "../../utils/create_project.js";
import createTask from "../../utils/create_task.js";

export default {
  components: { EventForm },
  beforeMount() {
    this.project = createProject();
    this.Task = createTask();
    var strn = this.$route.params;
    if (strn) {
      this.project = strn.project;
      this.task = strn.task;
      console.log(strn);
    } else {
      this.$router.push({ name: "Projects" });
    }
  },
  mounted() {
    this.get_items();
  },
  data() {
    return {
      table: "road_events",
      project: null,
      task: null,
      chosenPhoto: null,
      validMsg: false,
      broadcast: { email: null, subject: null, msg: null },
      broadcast_dialog: false,
      customToolbar: [
        [{ font: [] }],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ header: 1 }, { header: 2 }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      ],
      dialog: false,
      newItem: false,
      search: "",
      loading_status: false,
      errors: [],
      items: [],
      item: createItem(),
      headers: {
        false: [
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "Grupo",
            align: "start",
            sortable: true,
            value: "group_name",
            dataType: "text",
          },
          {
            text: "Dirección",
            align: "start",
            sortable: true,
            value: "address",
            dataType: "text",
          },
          {
            text: "Ciudad",
            align: "start",
            sortable: true,
            value: "city",
            dataType: "text",
          },
          {
            text: "Note",
            align: "start",
            sortable: true,
            value: "note",
            dataType: "text",
            width: "30%",
          },
          {
            text: "Fecha",
            align: "start",
            sortable: true,
            value: "issue_date",
            dataType: "text",
          },
          {
            text: "Teléfono",
            align: "end",
            sortable: true,
            value: "mobile",
            dataType: "text",
          },
        ],
        true: [
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "",
            align: "start",
            sortable: true,
            value: "city",
            dataType: "text",
          },
          {
            text: "Teléfono",
            align: "end",
            sortable: true,
            value: "mobile",
            dataType: "text",
          },
        ],
      },
      issueDateFrom: getIssueDate(5),
      issueDateTo: getToday(),
      groupby: "",
      groups: [],
    };
  },
  methods: {
    handleClick(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    view_events(e) {
      console.log(e);
    },
    get_items() {
      var qry = {
        account: this.$store.state.profile.account,
        table: this.table,
        filters: [
          { field: "account", operator: "=", value: this.project.account },
          { field: "project", operator: "=", value: this.project.code },
          { field: "task", operator: "=", value: this.task.code },
        ],
      };
      console.log(qry);

      this.loading_status = true;
      webserver("get_dataTable", qry, (data) => {
        this.loading_status = false;
        this.items = data;
        console.log(data);
      });
    },
    refresh() {
      this.dialog = false;
      this.get_items();
    },
    getImagePhoto: function (account, src) {
      var currentdate = new Date();
      var url =
        "https://" +
        this.$store.getters.getHost +
        "/" +
        account +
        "/road_events/" +
        src +
        "?" +
        currentdate;
      return url;
    },
    newForm() {
      this.item = createItem();
      this.item.account = this.project.account;
      this.item.project = this.project.code;
      this.item.task = this.task.code;
      this.item.pr_length = this.task.pr_length;
      this.item.pr_width = this.task.pr_width;
      this.item.pr_height = this.task.pr_height;
      this.item.pr_area = this.task.pr_area;
      this.item.pr_volume = this.task.pr_volume;
      this.item.task = this.task.code;
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
